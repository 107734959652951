import { FormControl } from '@angular/forms';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'iwt-estate-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  browserLang: string;
  estates = [
    {
      offererMarketingKey: 'ObjektNummer 1',
      street: 'Straße',
      postalCode: 'PLZ',
      distributionType: 'Rent',
      active: false,
      entityUuid: '123-123-123-1',
      city: 'Ort',
    },
    {
      offererMarketingKey: 'ObjektNummer 2',
      street: 'Straße',
      postalCode: 'PLZ',
      distributionType: 'Buy',
      active: true,
      entityUuid: '123-123-123-2',
      city: 'Ort',
    },
    {
      offererMarketingKey: 'ObjektNummer 3',
      street: 'Straße',
      postalCode: 'PLZ',
      distributionType: 'Rent',
      active: true,
      entityUuid: '123-123-123-3',
      city: 'Ort',
    },
    {
      offererMarketingKey: 'ObjektNummer 4',
      street: 'Straße',
      postalCode: 'PLZ',
      distributionType: 'Buy',
      active: true,
      entityUuid: '123-123-123-4',
      city: 'Ort',
    },
    {
      offererMarketingKey: 'ObjektNummer 5',
      street: 'Straße',
      postalCode: 'PLZ',
      distributionType: 'Rent',
      active: true,
      entityUuid: '123-123-123-5',
      city: 'Ort',
    },
  ];
  showResultList = false;

  maxItemsControl = new FormControl(3);
  actionIconControl = new FormControl('trash');
  hasDividerControl = new FormControl(true);
  hasLinkControl = new FormControl(true);
  selectableControl = new FormControl(true);
  tokenControl = new FormControl();

  @ViewChild('linkedEstatesComponent') linkedEstatesComponent: ElementRef;
  openModal: 'resultList' | 'linkedEstates' | 'none' = 'none';

  constructor(public translate: TranslateService) {
    const estateHref = document.location.href.startsWith('http://localhost')
      ? 'http://localhost:3002/'
      : document.location.href;
    const webcomponents = [
      `${estateHref}estate-components.js`,
      'https://move.dev.estatepro.de/shared-components.js',
    ];

    this.loadWebComponents(webcomponents);
    this.initTranslateService();
  }

  loadWebComponents(urls: string[]) {
    const existingElement = document.getElementsByTagName('script')[0];
    urls.forEach((url) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = url;
      existingElement?.parentNode?.insertBefore(script, existingElement);
    });
  }

  initTranslateService() {
    this.translate.addLangs(['de', 'en']);
    this.translate.setDefaultLang('en');
    this.browserLang = this.translate.getBrowserLang() ?? 'en';
    this.translate.use(
      this.browserLang.match(/en|de/) ? this.browserLang : 'en',
    );
  }

  openChange(event: Event) {
    if ((event as CustomEvent).detail) {
      return;
    }

    this.openModal = 'none';
  }

  deleteItem(event: Event) {
    this.estates.splice(
      this.estates.findIndex(
        (e) => e.entityUuid === (event as CustomEvent).detail.entityUuid,
      ),
      1,
    );
  }

  backToResultList() {
    this.estates =
      this.linkedEstatesComponent?.nativeElement?.estates ?? this.estates;
    this.showResultList = true;
  }
}
