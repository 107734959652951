<div class="m-6">
  <h2 class="text-xl">{{ 'General.selectLanguage' | translate }}</h2>
  <br />
  <button
    class="rounded border py-4 px-6 min-w-[5rem] mr-2 bg-white hover:bg-neutral-200"
    [class.active]="translate.currentLang === 'en'"
    (click)="this.translate.use('en')"
  >
    en
  </button>
  <button
    class="rounded border py-4 px-6 min-w-[5rem] bg-white hover:bg-neutral-200"
    [class.active]="translate.currentLang === 'de'"
    (click)="this.translate.use('de')"
  >
    de
  </button>

  <br />
  <br />

  <label>browser-Language: {{ this.browserLang }}</label>
  <br />
  <label>selected-Language: {{ this.translate.currentLang }}</label>
  <br />
  <br />
  <hr />
  <br />

  <h2 class="text-xl">{{ 'Estate.linkedEstates' | translate }}</h2>
  <br />

  <div class="form-field">
    <div class="input-masked"></div>
    <input
      [formControl]="maxItemsControl"
      id="maxItems"
      [class.has-value]="maxItemsControl.value"
    />
    <label for="maxItems">maxItems</label>
  </div>

  <div class="form-field">
    <div class="input-masked"></div>
    <input
      [formControl]="actionIconControl"
      id="actionIcon"
      [class.has-value]="actionIconControl.value"
    />
    <label for="actionIcon">actionIcon (leave empty for no icon)</label>
  </div>

  <div class="toggle-switch">
    <button
      type="button"
      class="bg-gray-200"
      [class.bg-cyan-600]="hasDividerControl.value"
      (click)="hasDividerControl.setValue(!hasDividerControl.value)"
      id="hasDivider"
      role="switch"
    >
      <span
        aria-hidden="true"
        class="translate-x-0"
        [class.translate-x-5]="hasDividerControl.value"
      ></span>
    </button>
    <label for="hasDivider">hasDivider</label>
  </div>

  <div class="toggle-switch">
    <button
      type="button"
      class="bg-gray-200"
      [class.bg-cyan-600]="hasLinkControl.value"
      (click)="hasLinkControl.setValue(!hasLinkControl.value)"
      id="hasLink"
      role="switch"
    >
      <span
        aria-hidden="true"
        class="translate-x-0"
        [class.translate-x-5]="hasLinkControl.value"
      ></span>
    </button>
    <label for="hasLink">hasLink</label>
  </div>

  <div class="toggle-switch">
    <button
      type="button"
      class="bg-gray-200"
      [class.bg-cyan-600]="selectableControl.value"
      (click)="selectableControl.setValue(!selectableControl.value)"
      id="selectable"
      role="switch"
    >
      <span
        aria-hidden="true"
        class="translate-x-0"
        [class.translate-x-5]="selectableControl.value"
      ></span>
    </button>
    <label for="selectable">selectable</label>
  </div>

  <br />
  <br />

  <button
    class="rounded py-4 px-6 bg-yellow-400 hover:bg-yellow-300"
    (click)="openModal = 'resultList'"
  >
    Open definable result list
  </button>
  <ec-shared-modal
    #resultListModal
    [open]="openModal === 'resultList'"
    (openChange)="openChange($event)"
  >
    <div modalTitle>{{ 'Estate.linkedEstates' | translate }}</div>
    <div class="text-left" modalContent>
      <div class="border rounded-xl p-4 pb-2 mb-4">
        <div class="flex flex-row my-4 hover:opacity-50 cursor-pointer">
          <p class="grow">{{ 'Estate.linkedEstates' | translate }}</p>
          <ec-shared-icon
            class="flex-none w-4 h-4 m-auto block"
            [defaultSize]="false"
            name="chevron-right"
          ></ec-shared-icon>
        </div>
        <ec-estate-result-list
          [language]="translate.currentLang"
          [estates]="estates"
          [maxItems]="maxItemsControl.value"
          [emptyText]="'Message.noLinkedEstates' | translate"
          [hasDivider]="hasDividerControl.value"
          [selectable]="selectableControl.value"
          [hasLink]="hasLinkControl.value"
          [actionIcon]="actionIconControl.value"
          (actionEvent)="deleteItem($event)"
        ></ec-estate-result-list>
      </div>
    </div>
  </ec-shared-modal>

  <br />
  <br />
  <br />
  <hr />
  <br />

  <div class="form-field">
    <div class="input-masked"></div>
    <input
      [formControl]="tokenControl"
      id="authToken"
      [class.has-value]="tokenControl.value"
    />
    <label for="authToken">idToken (http, Bearer)</label>
  </div>

  <button
    class="rounded py-4 px-6 bg-yellow-400 hover:bg-yellow-300"
    (click)="showResultList = true; openModal = 'linkedEstates'"
  >
    Open result list with search
  </button>
  <ec-shared-modal
    #linkedEstatesModal
    [open]="openModal === 'linkedEstates'"
    (openChange)="openChange($event)"
  >
    <div modalTitle>
      @if (!showResultList) {
        <button
          type="button"
          id="back-icon"
          class="h-10 w-10 min-w-10 -ml-4 mr-2 rounded-full bg-white text-gray-400 hover:bg-neutral-200"
          (click)="backToResultList()"
        >
          <ec-shared-icon
            class="align-text-bottom text-black"
            name="chevron-left"
          /></button
        >{{ 'Estate.linkedEstates' | translate }}
      }
    </div>
    <div class="text-left" modalContent>
      @if (showResultList) {
        <div class="border rounded-xl p-4 pb-2 mb-4">
          <div
            class="flex flex-row my-4 hover:opacity-50 cursor-pointer"
            (click)="showResultList = false"
          >
            <p class="grow">{{ 'Estate.linkedEstates' | translate }}</p>
            <ec-shared-icon
              class="flex-none w-4 h-4 m-auto block"
              [defaultSize]="false"
              name="chevron-right"
            />
          </div>
          <ec-estate-result-list
            [language]="translate.currentLang"
            [estates]="estates"
            [maxItems]="maxItemsControl.value"
            [emptyText]="'Message.noLinkedEstates' | translate"
            [hasDivider]="hasDividerControl.value"
            [hasLink]="true"
            (moreEstatesEvent)="showResultList = false"
          />
        </div>
      }

      @if (!showResultList) {
        <ec-estate-linked-estates
          #linkedEstatesComponent
          [authorizationToken]="tokenControl.value?.replace('Bearer ', '')"
          [estates]="estates"
        />
      }
    </div>
  </ec-shared-modal>
</div>
